let aizibinScience = [
  {
    path: '/aizibin_science',
    name: '艾滋病学科',
    component: () => import('@/pages/specialBusiness/aizibin_science')
  },
  {
    path: '/public_service',
    name: '公共服务',
    component: () => import('@/pages/specialBusiness/public_service')
  }
]

export default aizibinScience
