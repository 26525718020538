let other = [
  {
    path: '/party_building',
    name: '艾滋病学科',
    component: () => import('@/pages/other/party_building')
  },
  {
    path: '/achievement',
    name: '成果展示',
    component: () => import('@/pages/other/achievement')
  },
  {
    path: '/hospital_ethics',
    name: '行风',
    component: () => import('@/pages/other/hospital_ethics')
  },
  {
    path: '/health',
    name: '健康',
    component: () => import('@/pages/other/health')
  },
  {
    path: '/article',
    name: '文章',
    component: () => import('@/pages/other/article')
  },
  {
    path: '/article_detail',
    name: '文章详情',
    component: () => import('@/pages/other/article_detail')
  },
  {
    path: '/training',
    name: '专项培训',
    component: () => import('@/pages/other/training')
  },
  {
    path: '/scientific_effort',
    name: '科研工作',
    component: () => import('@/pages/other/scientific_effort')
  },
  {
    path: '/continuing_education',
    name: '继续教育',
    component: () => import('@/pages/other/continuing_education')
  },
  {
    path: '/college_education',
    name: '院校教育',
    component: () => import('@/pages/other/college_education')
  }
]

export default other
