
/**
 * Created by hejikun on 2017/10/30 0030.
 */
import Router from 'vue-router'
import Vue from 'vue'
import routes from './routes'
import VueRouter  from 'vue-router'
Vue.use(VueRouter)

const mode = 'history'
const hashbang = true

const router = new Router({
  hashbang,
  mode,
  routes
})
//添加以下代码
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(Router)
router.beforeEach((to, from, next) => {
  // 设置页面title
  next()
})
router.afterEach((to, from, next) => {
  window.scrollTo(0,0)
})
window.$router = router

export default router
